<template>
  <v-card tile flat height="100%" width="100%" style="margin-top:65px;"> 
       <!-- && !ExportingtoPDFDialog -->
       
     <v-card v-if="CurrentEntity" flat tile class="transparent" style="margin-top: 50px;">
          <v-card-title class="largeoverline recordtoolbar white--text">
              Dispatch Instruction - {{RecordData.id}}<v-spacer>
            </v-spacer><v-btn dark :color="RecordData.Active_Quote ? 'warning' : 'success'" @click="ActivateQuoteView()">{{RecordData.Active_Quote ? 'Regenerate' : 'Generate'}} Quote</v-btn>
            <v-btn v-if="RecordData.Latest_Quote_PDF" @click="GetShortLivedURL(RecordData.Latest_Quote_PDF)">View Quote</v-btn>
             <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserisModerator || RecordData && RecordData.Order_Progress && RecordData.Order_Progress.Name === 'Approved'"
                                 v-if="userLoggedIn.id && RecordData.Order_Progress"
                                >
                        <template v-slot:activator="{ on }">
                           <v-chip v-if="RecordData.Order_Progress" small color="pop" class="white--text" v-on="on">
                              {{RecordData.Order_Progress.Name}}
                            </v-chip>
                        </template>
                        <v-list v-if="RecordData.Order_Progress">
                          <div  v-for="status in ProgressItems" :key="status.itemObjKey">
                          <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserisModerator"
                                 v-if="userLoggedIn.id"
                                >
                               <template v-slot:activator="{ on }">
                          <v-list-item  :class="status.Class" v-on="on">
                            {{status.Name}}
                          </v-list-item>
                               </template>
                               <v-card>
                                 <v-card-text>
                                 {{status.Warning}}
                                 </v-card-text>
                                 <v-card-actions>
                                   <v-btn @click="UpdateProgress(RecordData,status)">Proceed</v-btn>
                                 </v-card-actions>
                               </v-card>
                           </v-menu>
                           </div>
                        </v-list>
                  </v-menu>
          </v-card-title>
         
          <v-list-item  :style="ListStyle">
              <v-list-item-content>
              <v-list-item-title v-if="RecordData.Created_By">
                  {{RecordData.Created_By.Full_Name}}
              </v-list-item-title>
               <v-list-item-title v-if="!RecordData.Created_By">
                  Web Order
              </v-list-item-title>
              <v-list-item-subtitle v-if="RecordData.Created_On">
                  {{RecordData.Created_On.toDate()}}
              </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="RecordData.LookupObj">
                  <v-chip :to="'/'+RecordData.LookupObj.single.split(' ').join('')+'/'+RecordData.LookupObj.id">
                {{RecordData.LookupObj[RecordData.LookupObj.identifier]}}
            </v-chip>
            
              </v-list-item-action>
              <v-list-item-action v-if="!RecordData.LookupObj">
                  <v-chip :to="'/'+CurrentEntity.SingleName.split(' ').join('')+'/'+$route.params.id">
                {{RecordData[CurrentEntity.Primary_Field_Name]}}
            </v-chip>
              </v-list-item-action>
          </v-list-item>
           <v-card-text>
              <v-list class="background">
                  <v-list-item dense v-for="field in SOHeaderFields" :key="field.itemObjKey">
                      <v-list-item-content class="background darken-1" :style="field.CommonFieldType === 'Physical Address' || field.CommonFieldType === 'Postal Address' ? 'height: 350px;padding-left: 5px;' : 'height: 100%;padding-left: 5px;'">
                          {{field.DisplayName}}
                      </v-list-item-content>
                      <v-list-item-content class="background" style="padding-left: 5px;justify-self: end;" v-if="field.CommonFieldType !== 'Physical Address' && field.CommonFieldType !== 'Postal Address'">
                      <FieldViewerComponent  :field="field" :Record="RecordData"
                      />
                      </v-list-item-content>
                      <v-list-item-content class="background" v-if="field.CommonFieldType === 'Physical Address' || field.CommonFieldType === 'Postal Address'">
                         
                    <v-list class="transparent mx-4" outlined>
                        <v-list-item dense v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                <v-list-item-content class="configtext--text">
                                {{breakdownfield.DisplayName}}:
                                </v-list-item-content>
                                 <v-list-item-content class="datatext--text">
                                {{RecordData[breakdownfield.Name]}}
                                </v-list-item-content>
                             
                            </v-list-item>
                    </v-list>
                    <br v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                  <v-list-item  v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                        <v-layout class="justify-start">
                          <h4>View on Google Maps</h4>
                        </v-layout>
                        <v-layout class="justify-end">
                          <v-btn icon @click="ViewMapItem(field.FieldBreakdown)"><v-icon>mdi-google-maps</v-icon></v-btn>
                        </v-layout>
                    </v-list-item>
                      </v-list-item-content>
                      
                  </v-list-item>
              </v-list>
          </v-card-text>
          <v-card-text class="my-10">
              <LineItemsComponent @UpdateProgress="UpdateLineItemProgress" :Line_Items="Line_Items" :MasterTableObj="MasterTableObj"
              :RelatedObj="RecordData" :CurrentEntity="CurrentEntity" v-if="MasterTableObj" :TableCollectionRef="TableCollectionRef"
              :ParentDisabled="StatusDisabled"
              :System="System" :SystemEntities="SystemEntities" :Type="'SCM'" />
          </v-card-text>
      </v-card>  
    <v-layout class="black justify-center fill-height" row style="margin-top: 50px;" v-if="ExportingtoPDFDialog">
    
          <v-flex :xl="HideToolbars? 12 :8" :lg="HideToolbars? 12 :8" :md="HideToolbars? 12 :6" :sm="HideToolbars? 12 :6" :xs="HideToolbars? 12 :6">
              <v-card class="blue-grey darken-3" v-if="ActivePage && ComputedFunctionData" dark tile flat outlined height="100%" width="100%" style="padding:50px;" id="mainbuildercomp">
                                
            <v-layout class="justify-center">
                
                <v-card v-if="DocumentDisplayWidth !== 0 && DocumentDisplayHeight !== 0" flat tile light :width="DocumentDisplayWidth" :height="DocumentDisplayHeight" :style="ActivePageStyling">
                    
                <!-- <PDFExporter v-if="FullItem && !RefresingPreview" :ExporttoPDFFunctions="[ComputedFunctionData]" @ActivateActiveElement="ActivateActiveElement"					
                :ActivePage="ActivePage" :DocumentDisplayRatio="DocumentDisplayRatio" :ExportingTest="ExportingTest" :RecordasPDF="false"					
                :FullItem="FullItem" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog"  :FunctionData="ComputedFunctionData" :FunctionPages="Pages"					
                /> -->
                <PDFExporter :ExporttoPDFFunctions="[ComputedFunctionData]"		:PassedFunction="ComputedFunctionData"	@DeactivateExportingtoPDFDialog="DeactivateExportingtoPDFDialog"
        :FullItem="FullItem" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog" :ExportonLoad="true" :PushCompletedFile="!PDFDownloadOnly"					
        />
                </v-card>
            </v-layout>
            </v-card>
          </v-flex>
          
    </v-layout>
            
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import LineItemsComponent from '@/components/SuitePlugins/Warehousing/LineItemsComponent';
import FieldViewerComponent from '@/components/Database/FieldViewerComponent';
import PDFExporter from '@/components/SuitePlugins/PDFBuilder/PDFExporter'		
export default {
    props: ['System','SystemEntities'],
    components: {LineItemsComponent,FieldViewerComponent,PDFExporter},
    data() {
        return {
          NewQuote_Number: '',
          PDFDownloadOnly: true,
          ExportingtoPDFDialog: false,
          POLineHeaders:  [
              {text: 'Nr',propvalue: 'Nr',value: 'Nr',Type: 'Number Field',class: 'overline'},
              {text: 'Warehouse',Type: 'Lookup',LookupFieldName: 'Name',propvalue: 'Warehouse',value: 'Warehouse.Name',class: 'overline',TableHidden: true},
              {text: 'Item',propvalue: 'StockObj',value: 'StockObj.Item_Name',Type: 'Lookup',LookupFieldName: 'Item_Name',class: 'overline'},
              {text: 'Qty',propvalue: 'Qty',value: 'Qty',Type: 'Number Field',class: 'overline'},
              {text: 'Price',propvalue: 'Price',value: 'Price',Type: 'Number Field',IsCurrency: true,class: 'overline'},
              {text: 'Sub Total',propvalue: 'Sub_Total',value: 'Sub_Total',Type: 'Number Field',IsCurrency: true,class: 'overline'},
              {text: 'Supplier',propvalue: 'Supplier',value: 'Supplier.Supplier_Name',Type: 'Lookup',LookupFieldName: 'Supplier_Name',class: 'overline',TableHidden: true},
              {text: 'Created Date',propvalue: 'Created_On',value: 'Created_On',Type: 'Date',class: 'overline'},
              {text: 'Sales Order',propvalue: 'Sales_Order',value: 'Sales_Order.Name',class: 'overline',TableHidden: true},
            ],
            Bill_To_Address: {Name: 'Bill_To_Address',FieldBreakdown: []},
          Delivery_Address: {Name: 'Delivery_Address',FieldBreakdown: []},
          Invoice_Address: {Name: 'Invoice_Address',FieldBreakdown: []},
          Collection_Address: {Name: 'Collection_Address',FieldBreakdown: []},
            QuoteView: false,
            FunctionData: '',
            ActivePageNumber: 1,	
            LivePreviewMode: true,
            RefresingPreview: true,
            WarehouseEntries: [],
            ConsumeBulkQuantityArrayCounter: 0,
            SOFields: [
                {Name: 'Client'},
                {Name: 'Client Acc Nr'},
                {Name: 'Delivery (Y/N)'},
                {Name: 'Delivery Address (if Delivery)'},
                {Name: 'POC'},
                {Name: 'Order Total'},
                {Name: 'Payment Terms'},
                {Name: 'Desired Delivery Date'},
                {Name: 'Delivery Address (if Delivery)'}
            ],
            RecordData: '',
            SubColEntity: '',
            MasterTableObj: '',
            Line_Items: [],
            MainBuilderWidth: 0,					
            MainBuilderHeight: 0,	
            SelectedBillingAccount: '',
            NEWSOInvoiceDialog: true,
            HasClientRecord: false,
        }
    },
    computed:{
       DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
      InvoiceFinalPaymentDate(){
         let d = new Date()
         d.setDate(d.getDate() + 7);
        return d
      },
      LookupObjSelectedSalesOrder(){
        let obj = {
          id: this.RecordData.id,
          list: this.CurrentEntity.id,
          single: this.CurrentEntity.SingleName.split(' ').join(''),
          identifier: this.CurrentEntity.RecordPrimaryField.Name,
          [this.CurrentEntity.RecordPrimaryField.Name]: this.RecordData[this.CurrentEntity.RecordPrimaryField.Name]
        }
        return obj
      },
      SelectedWarehouse(){
        return {}
      },
      SelectedBusinessUnit(){
        return this.$store.state.BusinessUnitsArray.find(obj => obj.id === this.RecordData.Business_Unitid)
      },
      
        FullItem(){
          if(this.RecordData && this.RecordData.id){
           
           let POObject = Object.assign({},this.RecordData)
           if(this.NewQuote_Number){
          POObject.Quote_Number = this.NewQuote_Number
        }
           POObject.SubCollections = []  
        if(this.NEWSOInvoiceDialog){
          if(this.OrderforDelivery){
          POObject.Delivery = true
        }
        else{
          POObject.Delivery = false
        }
        POObject.Created_On = new Date()
        POObject.Client = ''
        POObject.Customer_Company = ''
        POObject.Customer_Contact = ''
        POObject.Customer_ID = ''
        if(this.RecordData && this.RecordData.User){
          POObject.Customer_Contact = this.RecordData.User.Full_Name
          POObject.Client = this.RecordData.User
          POObject.Clientid = this.RecordData.User.id
        }
        if(this.SelectedBillingAccount){
          POObject.Customer_Company = this.SelectedBillingAccount.Account_Name
          POObject.Customer_ID = this.SelectedBillingAccount.Customer_ID
        }
        let capass = true
        // POObject.Invoice_Number = 'Invoice (TBD) #0000001'
        // if(this.NewInvoice_Number){
        //   POObject.Invoice_Number = this.NewInvoice_Number
        // }
        POObject.Progress = {ID: 1000001,Name: 'Draft'}
        POObject.RecordPrimaryFieldName = 'Sales_Quote'
        POObject.Company_Name = this.SelectedBusinessUnit.Company_Name
        this.Collection_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Collection_Address_').join('Supplier_Collection_Address_')
          if(this.SelectedBillingAccount[brd.Name]){
            POObject[newname] = this.SelectedBillingAccount[brd.Name]
          }
          else{
            capass = false
          }
        })
        let dapass = true
        this.Delivery_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Delivery_Address_').join('SO_Delivery_Address_')
          if(this.RecordData[brd.Name]){
            POObject[newname] = this.RecordData[brd.Name]
          }
          else{
            dapass = false
          }
        })
        let btapass = true
        this.Bill_To_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Bill_To_Address_').join('PO_Bill_To_Address_')
          let brdname = brd.Name.split('Bill_To_Address_').join('Address_')
          //console.log(brdname)
          if(this.SelectedBusinessUnit[brdname]){
            POObject[newname] = this.SelectedBusinessUnit[brdname]
          }
          else{
            btapass = false
          }
        })
        POObject.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        POObject.Created_Byid = this.userLoggedIn.id
        POObject.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        POObject.Modified_Byid = this.userLoggedIn.id
        POObject.Created_On = new Date()
        POObject.Modified_On = new Date()
        POObject.BillingAccount = {Client_Reference: this.SelectedBillingAccount.Client_Reference,Customer_ID: this.SelectedBillingAccount.Customer_ID,id: this.SelectedBillingAccount.id}
        POObject.BillingAccountid = this.SelectedBillingAccount.id
        if(dapass){
        POObject.SO_Delivery_Address_Text = ``+POObject.SO_Delivery_Address_Street_Number+` `+POObject.SO_Delivery_Address_Street_Name+`, `+POObject.SO_Delivery_Address_Suburb+`, `+POObject.SO_Delivery_Address_Postal_Code+`<br>
        `+POObject.SO_Delivery_Address_City+`, `+POObject.SO_Delivery_Address_State_Province+`,<br>
        `+POObject.SO_Delivery_Address_Country_Region  
        }
        if(btapass){        
        POObject.PO_Bill_To_Address_Text = ``+this.SelectedBusinessUnit.Bill_To_Address_Street_Number+` `+this.SelectedBusinessUnit.Bill_To_Address_Street_Name+`, `+this.SelectedBusinessUnit.Bill_To_Address_Suburb+`, `+this.SelectedBusinessUnit.Bill_To_Address_Postal_Code+`<br>
        `+this.SelectedBusinessUnit.Bill_To_Address_City+`, `+this.SelectedBusinessUnit.Bill_To_Address_State_Province+`,<br>
        `+this.SelectedBusinessUnit.Bill_To_Address_Country_Region
        }
        // POObject.Warehouse = {Name: this.SelectedWarehouse.Name,id: this.SelectedWarehouse.id}
        // POObject.Warehouseid = this.SelectedWarehouse.id
        POObject.Business_Unit = {Name: this.RecordData.Business_Unit.Name, id: this.RecordData.Business_Unitid}
        POObject.Business_Unitid = this.RecordData.Business_Unitid
        POObject.Footer_Note = `<p>The amount of the Invoice is to be paid in full, on a "COD" basis. Payment to be made to the agreed Account.</p>`
        POObject.Sub_Total = this.POSubTotal  
        POObject.Invoice_Total = this.VatExclusiveInvoiceTotal
        //POObject.Supplier_Account_Type = this.SelectedBillingAccount.Billing_Type
        //alert(this.SelectedBillingAccount.Billing_Type.Name)
        POObject.Payment_Terms = ''
        if(this.SelectedBillingAccount.Billing_Type && this.SelectedBillingAccount.Billing_Type.Name === 'COD'){
          POObject.Payment_Terms = 'COD'
          POObject.Payment_Period = 0
        }
        else if(this.SelectedBillingAccount.Billing_Type && this.SelectedBillingAccount.Billing_Type.Name === 'Credit'){          
          POObject.Payment_Period = this.SelectedBillingAccount.Days_for_Payment
          POObject.Payment_Terms = 'Net '+this.SelectedBillingAccount.Days_for_Payment
        }
        //POObject.Supplier_Account_Type = this.SelectedBillingAccount.Billing_Type
        POObject.Shipping_Method = {ID: 1000001,Name: 'FOB'}
        POObject.Due_Date = this.InvoiceFinalPaymentDate
        POObject.Tax_Total = this.TaxTotal
        POObject.Tax_Percentage = this.TaxPercentage
        POObject.Discount_Total = this.DiscountValue
        POObject.Freight_Total = this.ShippingTotal
        if(typeof POObject.Delivery === ' undefined'){
          POObject.Delivery = !this.SelectedBillingAccount.Collection
        }
        POObject.Grand_Total = this.GrandTotal
        console.log(POObject)
        return POObject
        }
        else{
          return ''
        }
          }
        },
        POSubTotal(){
        let total = this.Line_Items.map(polineitem => {
          return polineitem.Unit_Price*polineitem.Qty
        }).reduce((a, b) => a + b, 0)
        return Number(total.toFixed(2))
      },
        GrandTotal(){
        let total = this.POSubTotal
        //console.log(total)
        if(this.DiscountValue !== 0){
          total = total-this.DiscountValue
        }
        if(this.ShippingTotal !== 0){
          total = total+this.ShippingTotal
        }
        if(this.TaxTotal !== 0){
          total = total+this.TaxTotal
        }
        return Number(total.toFixed(2))
      },
      VatExclusiveInvoiceTotal(){
        let total = this.POSubTotal
        if(this.DiscountValue !== 0){
          total = total-this.DiscountValue
        }
        if(this.ShippingTotal !== 0){
          total = total+this.ShippingTotal
        }
        return Number(total.toFixed(2))
      },
      ShippingTotal(){
        if(this.SelectedBillingAccount.Collection){
          return 0
        }
        else{
          return 0
        }
      },
      TaxPercentage(){
        return this.SelectedBusinessUnit && typeof this.SelectedBusinessUnit.Tax_Percentage !== 'undefined' ? this.SelectedBusinessUnit.Tax_Percentage : 0
      },
      TaxTotal(){
        if(this.SelectedBusinessUnit && this.SelectedBusinessUnit.id){
          if(this.SelectedBusinessUnit.Tax_Percentage !== 0){            
            let total = this.POSubTotal*(this.SelectedBusinessUnit.Tax_Percentage/100)
            return Number(total.toFixed(2))
          }
          else{
            return 0
          }
        }
        else{
            return 0
          }
        
      },
      DiscountValue(){
        if(this.SelectedBillingAccount && this.SelectedBillingAccount.id){
          if(typeof this.SelectedBillingAccount.Default_Discount !== 'undefined' && this.SelectedBillingAccount.Default_Discount !== 0){
            let total = this.POSubTotal*(this.SelectedBillingAccount.Default_Discount/100)
            return Number(total.toFixed(2))
          }
          else{
            return 0
          }
        }
        else{
            return 0
          }
        
      },
        ActiveWatermark(){
        if(this.ActivePage.PageHasWaterMark && this.ActivePage.PageWaterMarkObject){
          return this.ActivePage.PageWaterMarkObject.Path
        } 
      },	
      ActivePageStyling(){				
        //console.log(this.ActivePage.PageHasWaterMark,this.ActivePage.PageWaterMarkObject)	
          if(this.ActiveWatermark){					
             return { backgroundImage: `url('`+this.ActiveWatermark+`')`,backgroundSize: `cover` }					
          }					
          else{					
              return ''					
          }					
          					
         // return this.ActivePage					
      },
        Pages(){
            return this.ComputedFunctionData ? this.ComputedFunctionData.Pages : []
        },
        POLineHeadersforTable(){
        return this.POLineHeaders.filter(head => {
          return !head.TableHidden
        })
      },
        ComputedNewPOLineItemsTable(){
        let width = 0
       //just for now, but when we actually export different mainbuild height and diff table width (550px)
        let headerslength = this.POLineHeadersforTable.length
        let html = `<table style="width:550px;padding:5px;border: 1px solid grey;">
                    <tr>`
        this.POLineHeadersforTable.map(head => {
          html = html+`
          <th class="overline" style="text-align: left;">`+head.text+`</th>`
        })
        html = html+`
        </tr>`
        this.ComputedNewPOLineItems.map(lineitem => {
          html = html+`
          <tr class="detailslistoutline">`
          this.POLineHeadersforTable.map((head,headnindex) => {
            //console.log(head.propvalue,lineitem,lineitem[head.propvalue])
            let value = lineitem[head.propvalue]
            if(head.Type === 'Number Field' && head.IsCurrency){
              value = this.CurrencyFormatter(value,this.$store.state.DefaultCurrency.Currency)
            }
            else if(head.Type === 'Date' && !head.HasTimeInput){
              value = value.toDateString()
            }
            else if(head.Type === 'Lookup'){
              value = value[head.LookupFieldName]
            }
            html = html+`
            <td style="text-align: left;">`+value+`</td>`
            if(headnindex-1+2 === headerslength){
              html = html+`
              </tr>`
            }
          })
        })
        html = html+`
        </table>`
        return html
      },
      ComputedNewPOLineItems(){
        let arr = this.Line_Items.map((item,index) => {
        //   if(typeof item.Delivery_DateMenu === 'undefined'){
        //     item.Delivery_DateMenu = false
        //   }
          if(item.Created_On.toDate){
              item.Created_On = item.Created_On.toDate()
          }
          if(!item.Price){
            item.Price = item.Unit_Price
          }
        //   if(typeof item.Delivery_Date_Input === 'undefined'){
        //       let date = item.Created_On
        //       if(date.toDate){
        //           date = date.toDate()
        //       }
        //     item.Delivery_Date_Input = date.toISOString().substring(0,10)
                     
        //   }
          
          item.Nr = index-1+2
          return item
        }).map(item => {
          //item.Created_On = new Date(item.Delivery_Date_Input)    
          item.Sub_Total = Number(item.Qty)*Number(item.Price)
          return item
        })
        return arr
      },
        ComputedFunctionData(){
          if(this.FunctionData){
           //console.log(this.FunctionData)
          let functiondata = JSON.parse(JSON.stringify(this.FunctionData))
          let newelemnt = {Name: '',AllowColumnRollover: false, AllowPageRollover: false,ForceNewColumn: false,ForceNewPage: false,LineColor: {r: 0,g:0,b:0},DrawColor: {r: 0,g:0,b:0},FillColor: {r: 0,g:0,b:0},FontColor: {r: 89,g:89,b:89}}
        newelemnt.Name = 'Goods Table'
        newelemnt.OutputType = 'HTML'
        newelemnt.RawHTML = true
        newelemnt.RawHTMLid = 'ComputedNewPOLineItemsTable'
        newelemnt.ElementWidth = 550
        newelemnt.DataSource = 'Custom Text'
        newelemnt.TextValue = this.ComputedNewPOLineItemsTable
        newelemnt.Justify = 'center'
        if(this.ComputedNewPOLineItems.length <= 3){
          newelemnt.PaddingTop = 425
        }
        else if(this.ComputedNewPOLineItems.length <= 6){
          newelemnt.PaddingTop = 400
        }
        else if(this.ComputedNewPOLineItems.length <= 9){
          newelemnt.PaddingTop = 375
        }
        else if(this.ComputedNewPOLineItems.length <= 12){
          newelemnt.PaddingTop = 350
        }
        else if(this.ComputedNewPOLineItems.length <= 15){
          newelemnt.PaddingTop = 325
        }
        else if(this.ComputedNewPOLineItems.length <= 18){
          newelemnt.PaddingTop = 300
        }
        else {
          newelemnt.PaddingTop = 300
        }
        // let parentelmnt = functiondata.Pages[0].Elements.find(obj => obj.Name === 'Top Header Rect')
        // newelemnt.InheritTopPosition = true
        // newelemnt.InheritedTopPositionParent = parentelmnt
        // newelemnt.InheritedTopPosition = 'end'
        //if 3 or less then 450
        //else if 6 or less
        newelemnt.PaddingLeft = 0
        newelemnt.WidthOption = 'Full Page'
        //console.log(newelemnt)
        functiondata.Pages[0].Elements.push(newelemnt)
        return functiondata 
          }
          
        },
        CurrentEntityCycleStart(){
            return this.CurrentEntity && this.CurrentEntity.Warehouse_Dispatch_After ? this.CurrentEntity && this.CurrentEntity.Warehouse_Dispatch_After.Name : ''
        },
        SOHeaderFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return field.Name !== 'Created_On' && field.Name !== 'Created_By' && field.Name !== 'Modified_On' && field.Name !== 'Modified_By'
            }).filter(field => {
                if(this.RecordData.User){
                    return field.Name !== 'Name' && field.Name !== 'Surname'
                }
                else{
                    return field
                }
            }).map(field => {
                if(field.Type === 'Lookup'){
                    field.Array = []
                }
                return field
            })
        },
        StatusDisabled(){
            //RecordData.Order_Progress.Name === 'Approved'
            if(this.RecordData && this.RecordData.Order_Progress){
                return this.RecordData.Order_Progress.Name === 'Completed' || this.RecordData.Order_Progress.Name === 'Cancelled' || this.RecordData.Order_Progress.Name === 'Returned'
            }
        },
        TableCollectionRef(){
            return db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.slug).collection('DispatchInstructionsLine_Items').where('Warehouseid','==',this.$route.params.slug).where('Transaction_Docid','==',this.$route.params.id)
        },
        UserisModerator(){
            return true
        },
        ProgressItems(){
            return [
                {ID: 1000001,Name: 'Preparing'},
                {ID: 1000002,Name: 'Proposed'},
                {ID: 1000003,Name: 'Approved'},
            ]
        },
        ListStyle(){
            return this.$vuetify.theme.dark ? 'border: 1px solid #dedee9;outline: 1px solid #dedee9;background: #787878;' : 'border: 1px solid #dedee9;outline: 1px solid #dedee9;background: #f8f8f8;'
        },
        OperationalDB(){
            return db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.slug).collection('DispatchInstructions').doc(this.$route.params.id)
        },
        ActiveWarehouse(){
            return this.WarehousesArray.find(obj => obj.id === this.$route.params.slug)
        },
        WarehousesArray(){
            return this.$store.state.WarehousesArray
        },
        CurrentEntity(){
            return this.SystemEntities.find(obj => obj.id === this.ActiveWarehouse.Sales_Order_Entity)
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        WindowWidth(){
            return window.innerWidth
        },
        ActivePage(){					
         return this.ModePageArray? this.ModePageArray.find(obj => obj.PageNumber === this.ActivePageNumber): '' 
         },	
         ModePageArray(){					
          if(this.LivePreviewMode){					
              return this.FunctionExportPages					
          }					
          else{					
              return this.Pages					
          }					
      },	
      FunctionExportPages(){					
          //console.log(this.ComputedFunctionData,this.FullItem)					
        if(this.ComputedFunctionData && this.FullItem){			
          let pdffunction = this.ComputedFunctionData					
          let pagescount = 0					
          let FunctionElements = []					
            let FunctionPages = JSON.parse(JSON.stringify(pdffunction.Pages))	
            //console.log('FunctionPages',FunctionPages)				
            FunctionPages.map(page => {					
              let pageobj = Object.assign({},page)					
              delete pageobj.Elements					
              //let pagenumber = page.PageNumber					
              if(page.PageDatafromArray && page.ArrayType && page.DynamicArrayName){
                let array = []
                if(page.ArrayType === 'SubCollection'){
                 array = this.FullItem.SubCollections.find(obj => obj.id === page.DynamicArrayName)
                }
                //console.log(array)			
                if(!page.AddPageperArrayItem){					
                    pagescount++					
                  }					
                  array.map((arritem,arritemindex) => {					
                    if(page.AddPageperArrayItem){					
                      pagescount++					
                    }					
                    page.Elements.map(elmnt => {                					
                    let elmntobj = Object.assign({},elmnt)					
                    elmntobj.fullobj = arritem					
                    elmntobj.PageObj = pageobj					
                      if(elmnt.ForceNewPage){					
                        					
                        if(elmnt.OutputType === 'Boolean Response'){					
                          if(arritem[elmnt.DataField]){					
                            //alert(arritem[elmnt.DataField])					
                            if(elmnt.BooleanTrueResponseType === 'Static' && elmnt.BooleanTrueResponse){					
                              //alert(elmnt.BooleanTrueResponse)					
                              					
                              pagescount++					
                              elmntobj.pagenumber = pagescount					
                              					
                              FunctionElements.push(elmntobj)					
                            }					
                            else if(elmnt.BooleanTrueResponseType === 'Dynamic' && arritem[elmnt.BooleanTrueResponseField]){					
                              elmntobj.BooleanTrueResponse = arritem[elmnt.BooleanTrueResponseField]					
                              //alert(elmntobj.BooleanTrueResponse)					
                              pagescount++					
                              elmntobj.pagenumber = pagescount					
                              FunctionElements.push(elmntobj)					
                            }					
                          }					
                          else if(!arritem[elmnt.DataField]){					
                            if(elmnt.BooleanFalseResponseType === 'Static' && elmnt.BooleanFalseResponse){					
                              pagescount++					
                              elmntobj.pagenumber = pagescount					
                              FunctionElements.push(elmntobj)					
                            }					
                             else if(elmnt.BooleanFalseResponseType === 'Dynamic' && arritem[elmnt.BooleanFalseResponseField]){					
                              elmntobj.BooleanFalseResponse = arritem[elmnt.BooleanFalseResponseField]					
                              pagescount++					
                              elmntobj.pagenumber = pagescount					
                              FunctionElements.push(elmntobj)					
                            }                            					
                          }					
                        }					
                        else{					
                          pagescount++					
                          elmntobj.pagenumber = pagescount					
                          FunctionElements.push(elmntobj)					
                        }                        					
                      }					
                      else{					
                        if(elmnt.OutputType === 'Boolean Response'){					
                          if(arritem[elmnt.DataField] && elmnt.BooleanTrueResponseType === 'Dynamic' && arritem[elmnt.BooleanTrueResponseField]){					
                           elmntobj.BooleanTrueResponse = arritem[elmnt.BooleanTrueResponseField]					
                          }					
                          else if(!arritem[elmnt.DataField] && elmnt.BooleanFalseResponseType === 'Dynamic' && arritem[elmnt.BooleanFalseResponseField]){					
                            elmntobj.BooleanFalseResponse = arritem[elmnt.BooleanFalseResponseField]                            					
                          }					
                        }					
                        elmntobj.pagenumber = pagescount					
                        FunctionElements.push(elmntobj)					
                      }					
                      					
                    })					
                    					
                  })					
                }					
                else{					
                  pagescount++					
                  page.Elements.map((elmnt,elmntindex) => {					
                    if(elmnt.ForceNewPage && elmntindex !== 0){					
                      pagescount++					
                    }					
                    let elmntobj = Object.assign({},elmnt)					
                    elmntobj.PageObj = pageobj          					
   					
                  					
                  elmntobj.pagenumber = pagescount					
                  FunctionElements.push(elmntobj)					
                  })					
                  					
                }             					
            })					
            					
            let exportpages = []					
            FunctionElements.map(elmnt => {					
              let pageobj = Object.assign({},elmnt.PageObj)					
              pageobj.PageNumber = elmnt.pagenumber					
              let oncheck = exportpages.find(obj => obj.PageNumber === pageobj.PageNumber)					
              if(!oncheck){					
                exportpages.push(pageobj)					
              }              					
            })					
            return exportpages.map(page => {					
              page.Elements = FunctionElements.filter(elmnt => {					
                return elmnt.pagenumber === page.PageNumber					
              })					
              return page					
            })					
        }					
      },	
    FunctionOutput(){
        return this.ComputedFunctionData ? this.ComputedFunctionData.FunctionOutput : ''
    },							
    DocumentDisplayRatio(){					
        if(this.FunctionOutput.orientation === 'p'){					
            return (this.DocumentDisplayHeight/this.FunctionOutput.Height)					
        }					
        else{					
          return (this.DocumentDisplayWidth/this.FunctionOutput.Width)					
        }					
    },					
    DocumentDisplayWidth(){					
       if(this.FunctionOutput.orientation === 'p'){					
            let ratio = 1					
            //console.log(this.MainBuilderHeight)					
            if(this.FunctionOutput.PixelHeight>this.MainBuilderHeight){					
                ratio = this.MainBuilderHeight/this.FunctionOutput.PixelHeight					
            }					
          return this.FunctionOutput.PixelWidth*ratio					
        }					
        else{					
            let ratio = 1					
            if(this.FunctionOutput.PixelWidth>this.MainBuilderWidth){					
                ratio = 1					
            }					
          return this.MainBuilderWidth*ratio					
        }					
        					
    },					
    DocumentDisplayHeight(){					
        if(this.FunctionOutput.orientation === 'p'){					
            let ratio = 1					
            if(this.FunctionOutput.PixelHeight>this.MainBuilderHeight){					
                ratio = 1					
            }					
          return this.MainBuilderHeight*ratio					
        }					
        else{					
            let ratio = 1					
            //console.log(this.MainBuilderWidth)					
            if(this.FunctionOutput.PixelWidth>this.MainBuilderWidth){					
                ratio = this.MainBuilderWidth/this.FunctionOutput.PixelWidth					
            }					
          return this.FunctionOutput.PixelHeight*ratio					
        }					
    },		
    },
    watch: {	
        FunctionExportPages(value){					
          if(value && value.length > 0){		
           setTimeout(() => {
             this.GetSizes()	
           }, 1000);				
           				
          }					
      },	
      ActivePage(value){					
          if(value){	            
              this.GetSizes()					
          }					
      },	
        				
    FullItem: {
            handler: function(oldvalue, newvalue) {              
              if(oldvalue !== newvalue){
                this.RefreshLivePreview()           
              }
            },
            deep: true
        },
    },
    
    created(){
        this.PrepareAddressField('Bill_To_Address')
        this.PrepareAddressField('Delivery_Address')
        this.PrepareAddressField('Invoice_Address')
        this.PrepareAddressField('Collection_Address')  
        this.MasterTableObj = this.CurrentEntity.SubCollections.find(obj => obj.LookupBuilds && obj.LookupBuilds[0] && obj.LookupBuilds[0].Entity_Type && obj.LookupBuilds[0].Entity_Type.Name === 'Store Product')
        //CurrentEntity
        this.GetDispatchInstruction()
        this.GetLineItems(this.TableCollectionRef)
    },
    methods:{
      CheckClientRecord(){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').doc(this.RecordData.id).onSnapshot(snapshot => {
                    let clientrecordata = snapshot.data()
                    if(clientrecordata){
                      this.HasClientRecord = true
                    }
            })
      },
      GetShortLivedURL(item){
      console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
        })
    },
      DownloadPO(downloadonly){
        this.ExportingtoPDFDialog = true
        this.PDFDownloadOnly = downloadonly
        setTimeout(() => {
             this.GetSizes()	
           }, 1000); 
      },
      ProceedGenerateQuote(){
        let vm = this
        vm.$store.commit('setCustomProcessingDialog',true)
          vm.$store.commit('setCustomProcessingDialogText','Registering PO')
        // this.ExportingtoPDFDialog = !this.ExportingtoPDFDialog
        // if(this.ExportingtoPDFDialog){
        //  setTimeout(() => {
        //      this.GetSizes()	
        //    }, 1000); 
        // }
        this.PDFDownloadOnly = false
          const functions = firebase.functions();
          const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
          let payload = {
            Docid: 'Sales_Quotes_Quote_Number',
            Data: {},
            siteid: this.$store.state.ActiveSuiteid
          }
          AssignAutoNumber(payload).then(result => {
          //console.log(result)
          //console.log(result.data)
          vm.NewQuote_Number = result.data
          vm.$store.commit('setCustomProcessingDialogText','Creating PDF')
          vm.DownloadPO(vm.PDFDownloadOnly)
          })
      },
      ActivateQuoteView(){
        if(this.RecordData.Active_Quote){
          confirm('Are you sure you want to replace the Current Quote '+this.RecordData.Active_Quote.id+'?') && this.ProceedGenerateQuote()
        }
        else{
          this.ProceedGenerateQuote()
        }
        
      },
      DeactivateExportingtoPDFDialog(doc){        
        if(!this.PDFDownloadOnly){
         this.ProcessNewQuote(doc) 
        }        
        this.ExportingtoPDFDialog = false
        this.PDFDownloadOnly = true
      },
      ProcessNewQuote(doc){
        let vm = this
        vm.$store.commit('setCustomProcessingDialogText','Uploading PDF')
        //console.log(this.NewPOLineItems,this.SelectedTransactionLineItems,this.ComputedSOInvoiceObject)
        let NewPOObject = Object.assign({},this.FullItem)
        delete NewPOObject.id
        if(this.NewQuote_Number){
          NewPOObject.Quote_Number = this.NewQuote_Number
        }
        //so actually we need to get autonumber. But first let's create autonumber field, see it's logic and take it from there see if it can be used
        var blobpdf = new Blob([doc.output('blob')],{type: 'application/pdf'})
        var bloburl = URL.createObjectURL(blobpdf)  
        //console.log(blobpdf,bloburl)
        let storagelocation = 'Sales_Quotes/'+NewPOObject.Quote_Number
         var storageRef = firebase.storage().ref(storagelocation);
                    var uploadTask = storageRef.put(blobpdf);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                          vm.$store.commit('setCustomProcessingDialogText','Preparing Invoice')
                          let PDF_File = {
                            StorageRef: storagelocation,
                            Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Modified_Byid: vm.userLoggedIn.id,
                            Modified_On: new Date(),
                            Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Created_Byid: vm.userLoggedIn.id,
                            Created_On: new Date(),
                            fileurl: url,
                            FileType: 'application/pdf',
                            ModifiedDate: new Date(),
                            Name: NewPOObject.Quote_Number,
                            filename: NewPOObject.Quote_Number
                          }

                          
                          NewPOObject.Latest_PDF_File = PDF_File
                          NewPOObject.LookupObj = vm.LookupObjSelectedSalesOrder
                          delete NewPOObject.RecordPrimaryFieldName
                          console.log(NewPOObject,vm.ComputedNewPOLineItems,vm.SelectedTransactionLineItems,vm.ComputedSOLineItems)
                            vm.$store.commit('setCustomProcessingDialogText','Preparing PO Lines')
                            
                            let Status = vm.DefaultStatusField.Options.find(obj => obj.Name === 'Active')
                            let StatusReason = Status.Options.find(obj => obj.Name === 'Open')
                            let DeactivateOld = false
                            let PreviousDoc = ''
                            let PreviousStatus = vm.DefaultStatusField.Options.find(obj => obj.Name === 'InActive')
                            let PreviousStatusReason = PreviousStatus.Options.find(obj => obj.Name === 'Closed')
                            NewPOObject.Status = Status
                            NewPOObject.Status_Reason = StatusReason
                            if(vm.RecordData.Active_Quote){
                              DeactivateOld = true
                              PreviousDoc = vm.RecordData.Active_Quote.id
                            }
                            let PrimaryString = NewPOObject.Invoice_Number
                            var lowertext = "";                
                            var p;
                            let buildup = ''
                            //console.log(PrimaryString.length,PrimaryString)
                            for (p = 0; p < PrimaryString.length; p++) {
                                buildup = buildup+ PrimaryString[p]
                                if(p === PrimaryString.length-1){
                                    lowertext += buildup.toLowerCase();
                                }
                                else{
                                    lowertext += buildup.toLowerCase() + ",";
                                }
                            } 
                            NewPOObject.SearchQuery = []
                            NewPOObject.SearchQuery = NewPOObject.SearchQuery.concat(NewPOObject.Invoice_Number.trim().split(' '))         
                            NewPOObject.SearchQuery = NewPOObject.SearchQuery.concat(lowertext.split(','))  
                            NewPOObject.SearchQuery = NewPOObject.SearchQuery.map(entry => {
                                return entry.toLowerCase()
                            })
                            vm.$store.commit('setCustomProcessingDialogText','Sending to Server')
                            let bulkoutboundpayload = {
                                    Collection: 'Sales_Quotes',
                                    ParentObj: NewPOObject,
                                    IDProp: 'Quote_Number',
                                    siteid: this.$store.state.ActiveSuiteid
                                    //we do not update "lookupobj" now it does not matter
                                  }
                                  if(DeactivateOld){
                                    bulkoutboundpayload.DeactivateOld = true
                                    bulkoutboundpayload.PreviousDoc = PreviousDoc
                                    bulkoutboundpayload.PreviousStatus = PreviousStatus
                                    bulkoutboundpayload.PreviousStatusReason = PreviousStatusReason
                                  }
                                  console.log('bulkoutboundpayload',bulkoutboundpayload)                               
                                  const functions = firebase.functions();
                                  const CreateFinancialDocument = functions.httpsCallable('CreateFinancialDocument');
                                  CreateFinancialDocument(bulkoutboundpayload).then(result => {
                                    vm.$store.commit('setPDFExportDoc','')
                                    vm.$store.commit('setPDFDataRecord','')
                                    vm.$store.commit('setOrderConfirmationPDF','')
                                    vm.$store.commit('setCustomProcessingDialogText','Server Responded - '+NewPOObject.Quote_Number)
                                      setTimeout(() => {
                                        //maybe in this instance we upload the df file to the SO right?
                                        //1. update "Active_Quote" value in SO with this doc as lu field
                                        //2. put pdf in as "Latest_Quote_PDF"
                                        vm.OperationalDB.update({
                                          Active_Quote: {id: NewPOObject.Quote_Number,Quote_Number: NewPOObject.Quote_Number},
                                          Latest_Quote_PDF: PDF_File
                                        }).then(final => {
                                          let record = vm.RecordData
                                          let NewComms = {
                                            description: NewPOObject.Quote_Number,
                                            Created_On: new Date(),   
                                            Created_By: {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name},
                                            Created_Byid: vm.userLoggedIn.id,
                                            senderid: vm.userLoggedIn.id,
                                            relatedid: record.id,
                                            relatedtype: vm.CurrentEntity.DisplayName,
                                            recordlocation: '/'+vm.CurrentEntity.SingleName.split(' ').join('')+'/'+record.id,
                                            topicid: vm.CurrentEntity.id,
                                          }
                                          NewComms.StorageRef = storagelocation
                                          NewComms.newdocid = NewPOObject.Quote_Number
                                          NewComms.FileType = PDF_File.FileType
                                          NewComms.lastModifiedDate = PDF_File.ModifiedDate
                                          NewComms.fileurl = PDF_File.fileurl
                                          NewComms.Uploadfilename = NewPOObject.Quote_Number
                                          vm.SaveNewClientcomms(NewComms,record)
                                         vm.$store.commit('setCustomProcessingDialog',false)
                                          setTimeout(() => {
                                            // /WarehouseTransaction/Z8WSyTNan523HzF3GOWk/INV-00000002?TransactionType=DispatchInstructions
                                              //vm.$router.push('/DispatchInstruction/'+vm.SelectedWarehouse.id+'/'+NewPOObject.Invoice_Number)
                                            }, 500)
                                        }, 500); 
                                        })
                                        
                                  })
                          //  })
                          
                          //now I got the Po object, but I still need 1. PO Line items, and 2.SO Line items...
                        })
      },
      SaveNewClientcomms(NewComms,record){
        //  commscol = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison')
             // db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').doc(record.id)
         let clientrecordobj = {
                name: record[this.CurrentEntity.RecordPrimaryField.Name],
                topicid: this.CurrentEntity.id,
                Owner: {id: record.Owner.id, Full_Name: record.Owner.Full_Name},
                Ownerid: record.Ownerid,
                Clientid: record.Userid
            }  
          if(!this.HasClientRecord){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').doc(record.id).set(clientrecordobj) 
          }
         
        let commscol = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison')
        //console.log(commscol)
        let owneruser = record.User
        let owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
        NewComms.recipientid = owner.id
          //console.log(NewComms)
           commscol.add(NewComms).then(doc => {            
            if(NewComms.Created_By.id !== owner.id){
                  let NewNotification = {
                    itemidprop: 'clientrecords',
                    itemid: NewComms.relatedid,
                    topicid: NewComms.topicid,
                    Type: 'Feedback',
                    Header: 'Response on '+record[this.CurrentEntity.RecordPrimaryField.Name],
                    Content: NewComms.description,
                    CreatorFullName: NewComms.Created_By.Full_Name,
                    CreatorID: NewComms.Created_By.id,
                    Owner: owner,
                    Ownerid: owner.id,
                    Read: false,
                    Path: '/MyAccount',                
                    IsLiaison: true,
                    Liaisonid: doc.id,
                    Message: NewComms.Created_By.Full_Name+' has responded on '+record[this.CurrentEntity.RecordPrimaryField.Name],
                    }
                    if(NewComms.fileurl){
                        NewNotification.fileurl = NewComms.fileurl
                    }
                    if(NewComms.ActiveProcessNumber){
                        NewNotification.ActiveProcessNumber = NewComms.ActiveProcessNumber
                    }
                    //console.log(NewNotification)
                this.$store.dispatch('createNotification',NewNotification) 
               }
            }) 
            
      },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      PrepareAddressField(fieldname){
            let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        },
        GetSizes(){				
        let elmnt = document.getElementById('mainbuildercomp')					
        setTimeout(() => {					
        //console.log(elmnt.getBoundingClientRect())					
        if(elmnt && this.ActivePage && this.MainBuilderHeight === 0){					
            this.MainBuilderHeight = this.WindowHeight*1.4				
            this.MainBuilderWidth = this.WindowWidth*1.4
            // this.MainBuilderHeight = elmnt.clientHeight					
            // this.MainBuilderWidth = elmnt.clientWidth				
        }    					
        }, 1000);					
        					
    },
        RefreshLivePreview(){					
        this.RefresingPreview = true					
          setTimeout(() => {					
            this.RefresingPreview = false					
          }, 200);					
      },	
        GetPDFFunction(){
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').doc('Sales_Quotes').onSnapshot(snapshot => {
          let functiondata = snapshot.data()
          this.FunctionData = functiondata
          console.log(this.FunctionData)
        })
      },
        ViewMapItem(FieldBreakdown) {
        let combinedlink = ''
        FieldBreakdown.map((breakdown,i) => {
            combinedlink = combinedlink+this.RecordData[breakdown.Name]
            if(i-1+2 < FieldBreakdown.length){
                combinedlink = combinedlink+'+'
            }
        })
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        
        window.open(FinalLink, '_blank')
      },
        UpdateProgress(item,status){
            if(status.Name === 'Approved'){
                this.PrepareConsumption(this.Line_Items)
            }
            item.Order_Progress = status
            console.log(item,status)
            this.OperationalDB.update({
                Order_Progress: status,
                Modified_Byid: this.userLoggedIn.id,
                Modified_By: {Full_Name: this.userLoggedIn.Full_Name,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,id: this.userLoggedIn.id}
            })
            //if "Approved" then run bulk consume
            
        },
        PrepareConsumption(array){
            console.log('array',array)
            this.ConsumeBulkQuantityArrayCounter = 0
            
            this.WarehouseEntries = []
            array.map(item => {
                
                //exactly here we have LookupObj as StockObj, but even so, needs updated info, so need to clal stocobj. 
                //why? 
                // 1) determine Stock Entity type config (precede/has inv manage etc)
                // 2) get stock qty
                //my view is for 1) we could call stock entity not use "item", or even use WH of item if it's there. Then we could do 2) like..functions do same. but yeah you need to know where.
                //so perhaps both 1 and 2 need decisions made prior. Decisions being, does Wh differ for same product table, 1 WH bypass crap another does not? other Q is , is this entire check not perhaps a
                console.log('item.StockObj',item.StockObj)
                let WHEntity = this.SystemEntities.find(obj => obj.id === item.LookupObj.list.split(' ').join('_'))
                this.ProcessSubColEntryConsume(item.id,item,item.StockObj,WHEntity,array)
            })
        },
        ProcessSubColEntryConsume(newdocid,NewObj,item,WHEntity,array){
    let vm = this
        let newarray = JSON.parse(JSON.stringify(array))
        let Purchased_With = {}
        let Purchase_Counter = 0
        if(item.StockObj && item.StockObj.Purchased_With){
            Purchased_With = item.StockObj.Purchased_With
        }
        if(item.StockObj && item.StockObj.Purchase_Counter){
            Purchase_Counter = item.StockObj.Purchase_Counter
        }
        Purchase_Counter = Purchase_Counter-1+2
        let pwarray = newarray.filter(pwitem => {
            return pwitem.id !== item.id
        })
            pwarray.map(pw => {
            if(Purchased_With[pw.id]){
                Purchased_With[pw.id] = Purchased_With[pw.id]-1+2
            }  
            else{
                Purchased_With[pw.id] = 1
            }
            })
        //this si what I am saying, I would rather call anotehr function here to update purchased_with and purchase_counter. do NOT do it with consume
        //also, consider doing it at the end consideringupdate to stock item may somehow impact line-items array and stuff.
      console.log('item',item,this.RecordData,this.CurrentEntity,WHEntity,NewObj)
    
      //now in fact if CurrentEntity is "Precede with Quote" then do not consume. 
      console.log(WHEntity.Primary_Field_Name,this.MasterTableObj)
      let Inventory_ArticleField = ''
      let payload = {
      Data: {
      LookupObj: {
        [this.CurrentEntity.Primary_Field_Name]: this.RecordData[this.CurrentEntity.Primary_Field_Name],
        id: this.RecordData.id,
        list: this.CurrentEntity.DisplayName,
        single: this.CurrentEntity.SingleName,
        identifier: this.CurrentEntity.Primary_Field_Name,
        SubColid: this.MasterTableObj.id,
        SubColRecordid: newdocid
      },
      StockObj: NewObj.LookupObj,
      Reference_Number: newdocid,
      Qty: NewObj.Qty,
      Created_By: NewObj.Created_By,
      Created_Byid: NewObj.Created_Byid,
      Modified_By: NewObj.Modified_By,
      Modified_Byid: NewObj.Modified_Byid,
      Progress: {ID: 1000005,Name: 'Completed'},
      Warehouseid: item.Warehouseid
      },
      //Collection: NewObj.LookupObj.list.split('_').join('').toLowerCase(),
      // id: NewObj.LookupObj.id
      }
      payload.Data.StockObj.UpdateStore = item.AddedtoStore
      console.log(Inventory_ArticleField,item.Business_Unitid,item.Inventory_Articleid)
      let storprodent = this.SystemEntities.find(obj => obj.id === NewObj.LookupObj.list.split(' ').join('_'))
      if(storprodent){
        Inventory_ArticleField = storprodent.AllFields.find(obj => obj.id === 'Inventory_Article')
        if(Inventory_ArticleField && item.Warehouseid && item.Inventory_Articleid){
          payload.Data.StockObj.StoreCollection = Inventory_ArticleField.RelatedBuildID
          payload.Data.StockObj.WarehouseDoc = item.Inventory_Articleid
          payload.Data.StockObj.WarehouseID = item.Warehouseid
        }
      }      
      if(item.Bypass_Picking_and_Packing){
        payload.Data.Bypass_Picking_and_Packing = item.Bypass_Picking_and_Packing
        payload.Data.Progress = {ID: 1000005,Name: 'Completed'}
        payload.Collection = 'DispatchInstructions'
      }
      else{
        payload.Collection = 'PickingSlips'
        payload.Data.Progress = {ID: 1000001,Name: 'Issued'}
      }
      console.log(payload)
      const functions = firebase.functions();
      const ConsumeStockQty = functions.httpsCallable('ConsumeStockQty');
      const ConsumeBulkStockQty = functions.httpsCallable('ConsumeBulkStockQty');
      const UpdateItemPurchaseStats = functions.httpsCallable('UpdateItemPurchaseStats');
      // if(item.Bypass_Picking_and_Packing){
      //  ConsumeStockQty(payload).then(result => {
      //   vm.DeactivateSubColLookupDialog()
      // console.log(result)
      // }) 
      // }
      // else{
        let arraydataentry = Object.assign({},payload)
        arraydataentry.Data.Warehouse = {
          LookupObj: {},
          Available: item.Available,
          // Price: arraydataentry.Data.Price,
          // Purchase_Leadtime: item.Purchase_Leadtime,
          // Supply_LeadTime: item.Supply_LeadTime,
          id: item.Warehouseid
        }
        let ouboundrecord = JSON.parse(JSON.stringify(arraydataentry))
        let inboundrecord = JSON.parse(JSON.stringify(arraydataentry))
        let inboundqty = NewObj.Qty-item.Available
        let whoncheck = vm.WarehouseEntries.find(obj => obj.id === item.Warehouseid)
        if(!whoncheck){
          vm.WarehouseEntries.push({id: item.Warehouseid,InboundStockRecords: [],OutboundStockRecords: []})
        }
        let finalwhcheck = vm.WarehouseEntries.find(obj => obj.id === item.Warehouseid)
        //console.log('inboundqty',inboundqty)
        if(inboundqty <= 0){
          finalwhcheck.OutboundStockRecords.push(ouboundrecord)
        }
        else{      
          if(item.Available > 0){
            ouboundrecord.Data.Qty = item.Available
            finalwhcheck.OutboundStockRecords.push(ouboundrecord)
          }
          inboundrecord.Data.Qty = inboundqty
          finalwhcheck.InboundStockRecords.push(inboundrecord)
        }
        
        
        vm.ConsumeBulkQuantityArrayCounter = vm.ConsumeBulkQuantityArrayCounter-1+2
        if(vm.ConsumeBulkQuantityArrayCounter === array.length){
          vm.WarehouseEntries.map(wh => {
           console.log('wh.OutboundStockRecords',wh.OutboundStockRecords)
           console.log('wh.InboundStockRecords',wh.InboundStockRecords)
          let bulkoutboundpayload = {
            Collection: payload.Collection,
            Data: wh.OutboundStockRecords,
            ParentObj: {LookupObj: payload.Data.LookupObj,
            Progress: payload.Data.Progress,
            Created_By: {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name},Created_Byid: vm.userLoggedIn.id,
            Modified_By: {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name},Modified_Byid: vm.userLoggedIn.id},
            Warehouse: wh.id
          }
          if(wh.OutboundStockRecords.length > 0){
            console.log('doing OutboundStockRecords')
            ConsumeBulkStockQty(bulkoutboundpayload).then(result => {
              //vm.DeactivateSubColLookupDialog()
            console.log(result)
            }) 
          }
          if(wh.InboundStockRecords.length > 0){
            console.log('doing InboundStockRecords')
            let bulkinboundpayload = Object.assign({},bulkoutboundpayload)
            bulkinboundpayload.Data = wh.InboundStockRecords
            bulkinboundpayload.Collection = 'PurchaseRequisitions'
            ConsumeBulkStockQty(bulkinboundpayload).then(result => {
              //vm.DeactivateSubColLookupDialog()
            console.log(result)
            }) 
          } 
          })          
        }
        //tehre is a risk that updating pruchasedstats may impact "Line_Items", although trigger and array assignment happens much sooner so I do doubt it...
        UpdateItemPurchaseStats({StockObj: item.StockObj, Purchased_With: Purchased_With, Purchase_Counter: Purchase_Counter})
      // }
      
  },
        UpdateLineItemProgress(item,status){
            item.Progress = status
            this.OperationalDB.doc(item.id).update({
                Progress: status,
                ModifiedByid: this.userLoggedIn.id,
                ModifiedBy: {Full_Name: this.userLoggedIn.Full_Name,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,id: this.userLoggedIn.id}
            })
        },
        GetSoBillingAccount(){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(this.RecordData.BillingAccount.id).onSnapshot(snapshot => {
                let billingdata = snapshot.data()
                this.SelectedBillingAccount = {...billingdata,id: this.RecordData.BillingAccount.id}
                console.log(this.SelectedBillingAccount)
            })
        },
        GetDispatchInstruction(){
            console.log(this.OperationalDB)
            this.OperationalDB.onSnapshot(snapshot => {
                this.RecordData = snapshot.data()
                this.RecordData.id = this.$route.params.id
                console.log(this.RecordData)
                this.CheckClientRecord()
                this.GetSoBillingAccount()
                if(this.RecordData){
                    this.GetPDFFunction()
                    if(this.MasterTableObj){
                        //console.log('this.MasterTableObj',this.MasterTableObj)
                        this.SubColEntity = this.SystemEntities.find(obj => obj.id === this.MasterTableObj.LookupBuilds[0].id)
                        this.MasterTableObj.LookupBuilds = this.MasterTableObj.LookupBuilds.map(build => {
                        let newbuildobj = {id: build.id, identifier: build.recordprimaryfield.Name,recordprimaryfield: build.recordprimaryfield}
                        let lookupbuild = this.SystemEntities.find(obj => obj.id === build.id)
                        //console.log('lookupbuild',lookupbuild,'build',build)
                        if(lookupbuild){
                            newbuildobj.AllFields = lookupbuild.AllFields
                        }
                        if(build.LinkedFields){
                            newbuildobj.LinkedFields = build.LinkedFields
                        }
                        if(build.single){
                            newbuildobj.single = build.single
                        }
                        if(lookupbuild.SubCollections){
                            newbuildobj.SubCollections = lookupbuild.SubCollections
                        }
                        if(lookupbuild.Entity_Type){
                            newbuildobj.Entity_Type = lookupbuild.Entity_Type
                            if(lookupbuild.Entity_Type.Name === 'Store Product'){                      
                            let articletablefield = lookupbuild.AllFields.find(obj => obj.id === 'Inventory_Article')
                            if(articletablefield){
                                newbuildobj.ArticleTableField = articletablefield
                            }
                            }
                        }
                        if(typeof lookupbuild.Bypass_Picking_and_Packing !== 'undefined'){
                            newbuildobj.Bypass_Picking_and_Packing = lookupbuild.Bypass_Picking_and_Packing
                        }
                        if(lookupbuild.Security_Structure){
                            newbuildobj.Security_Structure = lookupbuild.Security_Structure
                        }
                        //console.log('newbuildobj',newbuildobj)
                        // Bypass_Receiving
                        return newbuildobj
                        })
                        
                    }
                }
            })
        },
        GetWarehouseStockObj(lineitem){
            let vm = this
            return new Promise(function(resolve, reject) {
                //console.log('db',db.collection(lineitem.LookupObj.list.split('_').join('').split(' ').join('').toLowerCase()).doc(lineitem.LookupObj.id))
                db.collection(lineitem.LookupObj.list.split('_').join('').split(' ').join('').toLowerCase()).doc(lineitem.LookupObj.id).onSnapshot(snapshot => {
                    let stockdata = snapshot.data()
                    if(stockdata){
                        lineitem.StockObj = stockdata
                    }
                    resolve(lineitem)
                })
            })
        },
        GetLineItems(query){
            //console.log('query',query)
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let lineitem = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    this.GetWarehouseStockObj(lineitem).then(result => {
                        this.Line_Items.push(result)
                    })
                }
                else if (change.type === 'modified') {
                    let lineitem = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    this.GetWarehouseStockObj(lineitem).then(result => {
                        let arrayobj = this.Line_Items.find(obj => obj.id === lineitem.id)
                        let arrayobjindex = this.Line_Items.indexOf(arrayobj)
                        this.Line_Items.splice(arrayobjindex, 1, result);
                    })
                } 

                })
            })
        }
    }
}
</script>

<style>

</style>